<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import {
	CollectionDocument,
	type CollectionQuery,
} from '~/generated/operations'
import {
	CollectionSeriesCoverFlowMobile,
	CollectionSeriesCoverFlow,
} from '#components'

const props = defineProps<{
	slug: string
	total?: number
	index?: number
	prefix?: string
	heading?: string
	description?: string
}>()

const { data } = await useCachedQuery<CollectionQuery>({
	key: `series:${props.slug}`,
	query: CollectionDocument,
	variables: {
		slug: props.slug,
	},
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = ref(breakpoints.smaller('md').value)

watch(breakpoints.smaller('md'), () => {
	isMobile.value = breakpoints.smaller('md').value
})

const publications =
	data.value?.entry?.__typename === 'collection_series_Entry' &&
	data.value?.entry?.seriesToPublication.filter((p) => !p.hideCard)
</script>

<template>
	<ComponentSection
		class="series-section"
		color="white"
		:label="prefix ?? ''"
		:title="heading"
		:text="description"
	>
		<template #pill v-if="Number.isInteger(total)">
			<PillLabel class="series-section__label" color="black">
				Serie: {{ data.entry?.title }}
			</PillLabel>
		</template>

		<component
			:is="
				isMobile
					? CollectionSeriesCoverFlowMobile
					: CollectionSeriesCoverFlow
			"
			v-if="publications"
			:publications="publications"
		/>
	</ComponentSection>
</template>

<style scoped lang="postcss">
.series-section {
	@apply pt-6 mt-0 relative w-full bg-white overflow-hidden text-center border-t border-nemo-gray;

	.layout--homepage & {
		@apply sm:pb-20;
	}
}

.count {
	@apply bg-nemo-yellow w-[14px] inline-block rounded-full leading-[11px] pt-[3px] text-center;
}
</style>
